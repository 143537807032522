import React, { useState } from "react";
import { Box, Button, Drawer, Typography } from "@mui/material";
import Tab from "@mui/material/Tab";

import TabContext from "@mui/lab/TabContext";
import TabList from "@mui/lab/TabList";
import TabPanel from "@mui/lab/TabPanel";
import { useLocation, useNavigate } from "react-router";
import WarrantyImage from "src/assets/images/warranty.jpg";
import InsuranceImage from "src/assets/images/insurance.jpg";
import AssesoriesImage from "src/assets/images/acessesories.jpg";

import extendedWarranty from "src/assets/images/extand.svg";
import Accessories from "src/assets/images/accessories.svg";
import Insurance from "src/assets/images/insurance.svg";
import TabCard from "src/components/cards/TabCard";
import { BiError, BiSolidXCircle } from "react-icons/bi";
import RequestTab from "./RequestTab";
import { useSelector } from "react-redux";
import { RootState } from "src/store/Store";
import RedeemTab from "./RedeemTab";
const tab1 = [
  {
    title: "Extended Warranty",
    image: extendedWarranty,
    href: "/services/6",
    img: WarrantyImage,
    btnText: "Extend Warranty",
    description: (
      <div>
        <h2 className="text-left text-base font-semibold text-secondary mb-3">
          Extend Your Warranty, Earn More Rewards!
        </h2>
        <div>
          <Typography variant="subtitle2" className="text-justify !mb-2">
            Secure your vehicle's future and your loyalty points! Avail of an
            extended car warranty today and enjoy exclusive benefits, including:
          </Typography>
          <ul className="pl-5 list-disc flex flex-col gap-2">
            <li>
              <Typography variant="subtitle2" className="text-justify">
                <b>Bonus Loyalty Points:</b> Earn [X Points] instantly for choosing
                extended coverage.
              </Typography>
            </li>
            <li>
              <Typography variant="subtitle2" className="text-justify">
                <b> Extra Perks:</b> Redeem your points for discounts, services, and
                more!
              </Typography>
            </li>
          </ul>
          <Typography variant="subtitle2" className="text-justify">
            Protect your car while enhancing your rewards. Don’t miss out—extend
            your warranty now!</Typography>

        </div>
      </div>
    ),
  },
  {
    title: "Car Accessories",
    image: Accessories,
    href: "/services/7",
    img: AssesoriesImage,
    btnText: "Customize Your Car",
    description: (
      <div>
        <h2 className="text-left text-base font-semibold text-secondary mb-3">
          Upgrade Your Ride, Upgrade Your Rewards
        </h2>
        <div>
          <Typography variant="subtitle2" className="text-justify !mb-2">
            Enhance your car with premium accessories and earn loyalty points!
          </Typography>
          <ul className="pl-5 list-disc flex flex-col gap-2">
            <li>
              <Typography variant="subtitle2" className="text-justify">
                <b>Earn Points :</b>Instantly added to your account with every accessory purchase.
              </Typography>
            </li>
            <li>
              <Typography variant="subtitle2" className="text-justify">
                <b>Double the Benefits :</b>Personalize your vehicle while maximizing your rewards.
              </Typography>
            </li>
          </ul>
          <Typography variant="subtitle2" className="text-justify">
            Shop your favorite accessories today and watch your points grow!
          </Typography>

        </div>
      </div>
    ),
  },
  {
    title: "Insurance",
    image: Insurance,
    img: InsuranceImage,
    btnText: "Protect Your Ride",
    href: "/services/5",
    description: (
      <div>
        <h2 className="text-left text-base font-semibold text-secondary mb-3">
          Protect Your Car, Boost Your Points!
        </h2>
        <div>
          <Typography variant="subtitle2" className="text-justify !mb-2">
            Get rewarded for securing your vehicle! Purchase car insurance through our program and earn exclusive loyalty points.
          </Typography>
          <ul className="pl-5 list-disc flex flex-col gap-2">
            <li>
              <Typography variant="subtitle2" className="text-justify">
                <b>Earn Points :</b>Automatically added when you buy or renew car insurance.
              </Typography>
            </li>
            <li>
              <Typography variant="subtitle2" className="text-justify">
                <b>Drive Peacefully :</b>Enjoy coverage while maximizing your rewards.
                Protect your car, protect your journey—and watch your points grow!

              </Typography>
            </li>
          </ul>


        </div>
      </div>
    ),
  },
];

const PointsInfoTabs = () => {
  const [value, setValue] = React.useState("1");
  const location = useLocation();
  const navigate = useNavigate();
  const [isDrawer, setIsDrawer] = useState<{
    open: boolean;
    data: null | Record<string, any>;
  }>({
    open: false,
    data: null,
  });
  const { UserID } = useSelector((state: RootState) => state.user);
  const handleChange = (event: React.SyntheticEvent, newValue: string) => {
    setValue(newValue);
  };
  const isOnClick = location.pathname.includes("points");

  return (
    <div className="grid grid-cols-1">
      <Box sx={{ width: "100%", typography: "body1" }}>
        <TabContext value={value}>
          <Box sx={{ borderBottom: 1, borderColor: "divider" }}>
            <TabList
              onChange={handleChange}
              aria-label="lab API tabs example"
              sx={{}}
              scrollButtons={true}
              visibleScrollbar={false}
            >
              <Tab label="Earn Points Via" value="1" />
              <Tab label="Redeem Points" value="2" />
              {UserID ? <Tab label="Points Requests" value="3" /> : null}
            </TabList>
          </Box>
          <TabPanel value="1" className="!px-0 !pb-0">
            <div className="grid grid-cols-3 gap-4">
              {tab1?.map((x) => (
                <TabCard
                  {...x}
                  key={x.title}
                  onClick={
                    isOnClick
                      ? () =>
                        setIsDrawer({
                          open: true,
                          data: x,
                        })
                      : null
                  }
                />
              ))}
            </div>
          </TabPanel>
          <TabPanel value="2" className="!px-0">
            <div className="grid grid-cols-1 gap-4">
              <RedeemTab />
            </div>
          </TabPanel>

          <TabPanel value="3" className="!px-0">
            <div className="grid grid-cols-1 gap-4">
              <RequestTab />
            </div>
          </TabPanel>
        </TabContext>
      </Box>

      <Drawer
        open={isDrawer.open}
        onClose={() =>
          setIsDrawer({
            open: false,
            data: null,
          })
        }
        anchor="bottom"
        sx={{
          "& .MuiPaper-root": {
            borderTopLeftRadius: 25,
            borderTopRightRadius: 25,
            overflow: "visible",
          },
        }}
      >
        <BiSolidXCircle
          className="absolute  right-1 "
          size={35}
          onClick={() =>
            setIsDrawer({
              open: false,
              data: null,
            })
          }
        />
        <div className="px-[30px] py-8">
          <img
            src={isDrawer?.data?.img}
            alt="warranty"
            className="rounded-md w-full mb-5"
          />

          <Typography variant="subtitle1" className="text-justify !mb-5">
            {isDrawer.data?.description || ""}
          </Typography>
          <Button
            variant="contained"
            color="primary"
            className="w-full mt-2"
            onClick={() => navigate(isDrawer?.data?.href || "")}
          >
            {isDrawer?.data?.btnText || ""}
          </Button>
        </div>
      </Drawer>
    </div>
  );
};

export default PointsInfoTabs;
